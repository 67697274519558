//css-------------------
import '../../css/share/common.scss';
import '../../css/home/welcome.scss';

//swiper
import( /* webpackPrefetch: true */ 'swiper/css');
import( /* webpackPrefetch: true */ 'swiper/css/pagination');
import( /* webpackPrefetch: true */ 'swiper/css/navigation');

//fontawesome
import( /* webpackPrefetch: true */ '../../static/lib/fontawesome/fontawesome.scss');
import( /* webpackPrefetch: true */ '../../static/lib/fontawesome/regular.scss');
import( /* webpackPrefetch: true */ '../../static/lib/fontawesome/brands.scss');

//js----------------------
//components
import( /* webpackPrefetch: true */ '../share/components/_header');
import( /* webpackPrefetch: true */ '../share/components/_common_lazy');
import( /* webpackPrefetch: true */ '../share/components/_welcome_lazy');


//npm lib
import $ from "jquery";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import imagesLoaded from "imagesloaded";


$(function() {

    gsap.registerPlugin(ScrollTrigger);

    let blurElement = { a: 50 };
    let blurElement2 = { a: 80 };

    imagesLoaded("html, body", function() {
        setTimeout(() => {
            gsap.to(blurElement, {
                duration: 3.2,
                a: 0,
                onUpdate: function() {
                    gsap.set('.kv_snack', { webkitFilter: "blur(" + blurElement.a + "px)" });
                }
            });
            gsap.to('.kv_snack', { duration: 1.5, opacity: 1, ease: 'power0.out' })
            gsap.to(blurElement, {
                duration: 2,
                a: 0,
                onUpdate: function() {
                    gsap.set('.kv_center', { webkitFilter: "blur(" + blurElement.a + "px)" });
                }
            });
            gsap.to('.kv_center', { duration: .5, opacity: 1, ease: 'power0.out' })
        }, 600);
        setTimeout(() => {
            gsap.to(blurElement2, {
                duration: 2.5,
                a: 0,
                onUpdate: function() {
                    gsap.set('.kv_rock_1, .kv_rock_2, .kv_rock_3', { webkitFilter: "blur(" + blurElement2.a + "px)" });
                }
            });
            gsap.to('.kv_rock_1, .kv_rock_2, .kv_rock_3', { duration: 1.5, opacity: 1, ease: 'power0.out' })
        }, 1200);
    });


    let tween;

    tween = gsap.timeline({
            scrollTrigger: {
                trigger: '.kv_sec',
                start: 300,
            }
        })
        .to('.mainlogo', { duration: .6, opacity: 1, ease: 'power0.out' })



    let _duration = "200%";
    if ($(window).width() <= 950) {
        _duration = "150%";
    }

    tween = gsap.timeline({
            scrollTrigger: {
                trigger: '#trigger_whoweare_sec',
                start: 'top 70%',
                end: '+=' + _duration,
                scrub: 1,
            }
        })
        .to('.ball', { y: '-15vw', scale: 1, opacity: 1, ease: 'power0.out' })
        .to('.ball', { y: '20vw', scale: .95, opacity: 1, ease: 'power0.out' }, 'pin_1')
        .from('.tunnel', { y: 50, opacity: 0, ease: 'power0.out' }, 'pin_1')
        .to('.ball', { y: '40vw', scale: .9, opacity: 1, ease: 'power0.out' })
        .to('.ball', { y: '60vw', scale: .95, opacity: 1, ease: 'power0.out' })
        .to('.ball', { y: '80vw', scale: 1, opacity: 1, ease: 'power0.out' })

    if ($(window).width() <= 950) {
        tween.to('.ball', { duration: 1, y: '110vw', scale: 1, opacity: 0, ease: 'power0.out' })
    }



    tween = gsap.timeline({
            scrollTrigger: {
                trigger: '#trigger_whoweare_sec',
                start: 'top 20%',
                toggleActions: "play none none none",
            }
        })
        .from('.whoweare_maintitle', { duration: 1, x: -500, opacity: 0, ease: 'quart.out' })
        .from('.whoweare_logo', { duration: .8, x: 500, opacity: 0, ease: 'quart.out' }, '-=.7')
        .from('.whoweare_dec_1', { duration: .8, scale: 0, ease: 'back.out(.7)' }, '-=.7')
        .from('.whoweare_txt', { duration: .8, x: 500, opacity: 0, ease: 'quart.out' }, '-=.6')
        .from('.member_col', { duration: .6, stagger: .1, y: 200, opacity: 0, ease: 'quart.out' }, '-=.8')
        .from('.whoweare_more', { duration: .6, y: 20, opacity: 0, ease: 'quart.out' }, '-=.4')
        .from('.icon_arrow', { duration: .6, y: 150, opacity: 0, ease: 'quart.out' }, '-=.4')


    let _triggerHook = '90%';
    if ($(window).width() <= 950) {
        _triggerHook = '50%';
    }
    tween = gsap.timeline({
            scrollTrigger: {
                trigger: '#trigger_banner_sec',
                start: 'top ' + _triggerHook,
                toggleActions: "play pause resume reset",
            }
        })
        .to('.whoweare_maintitle', { duration: 1, y: -500, opacity: 0, ease: 'quart.in' })
        .to('.whoweare_logo', { duration: .8, y: -500, opacity: 0, ease: 'quart.in' }, '-=.8')
        .to('.whoweare_dec_1', { duration: .8, scale: 0, ease: 'back.in(.7)' }, '-=.7')
        .to('.whoweare_txt', { duration: .8, y: -500, opacity: 0, ease: 'quart.in' }, '-=.8')
        .to('.member_row', { duration: .6, y: -500, opacity: 0, ease: 'quart.in' }, '-=.8')
        .to('.whoweare_more', { duration: .6, y: -200, opacity: 0, ease: 'quart.in' }, '-=.5')
        .to('.icon_arrow', { duration: .6, y: -150, opacity: 0, ease: 'quart.in' }, '-=.5')



    tween = gsap.timeline({
            scrollTrigger: {
                trigger: '#trigger_media_sec',
                start: 'top 50%',
            }
        })
        .from('.media_maintitle', { duration: .8, x: -500, opacity: 0, ease: 'quart.out' })
        .from('.media_maintitle_dec', { duration: .8, x: -500, opacity: 0, ease: 'quart.out' }, '-=.6')
        .from('.grid_item', { duration: .8, stagger: .15, opacity: 0, ease: 'power1.out' }, '-=.6')
        .from('.grid_dec_1', { duration: .8, scale: 0, rotate: -360, ease: 'quart.out' }, '-=.6')
        .from('.grid_dec_2', { duration: .8, scale: 0, rotate: -360, ease: 'quart.out' }, '-=.5')
        .from('.media_sec .dec_geom', { duration: 1, scale: 0, opacity: 0, rotate: 360, ease: 'quart.out' }, '-=.5')
        .from('.media_sec .more_row', { duration: .6, x: 100, opacity: 0, ease: 'quart.out' }, '-=.8')
        .from('.media_sec .more_dec', { duration: .6, x: 100, opacity: 0, ease: 'quart.out' }, '-=.6')




    tween = gsap.timeline({
            scrollTrigger: {
                trigger: '#trigger_creation_sec',
                start: 'top 50%',

            }
        })
        .from('.creation_maintitle', { duration: 1, x: 500, opacity: 0, ease: 'quart.out' })
        .from('.creation_sec .banner', { duration: .8, y: 50, opacity: 0, ease: 'circ.out' }, '-=.7')
        .from('.creation_maintitle_dec', { duration: 1, opacity: 0, ease: 'power1.out' }, '-=.4')




    tween = gsap.timeline({
            scrollTrigger: {
                trigger: '#trigger_creation_sec_2',
                start: 'top 70%',

            }
        })
        .from('.creation_pic', { duration: 1, stagger: .13, y: 30, opacity: 0, ease: 'quart.out' })




    tween = gsap.timeline({
            scrollTrigger: {
                trigger: '#trigger_gallery_sec',
                start: 'top 50%',

            }
        })
        .from('.dec_graph_1', { duration: 1, x: 300, opacity: 0, ease: 'quart.out' })
        .from('.dec_graph_2', { duration: 1.5, x: -200, opacity: 0, ease: 'quart.out' }, '-=.8')
        .from('.gallery_maintitle_row', { duration: 1, x: -200, opacity: 0, ease: 'quart.out' }, '-=1.25')
        .from('.gallery_maintitle_dec_1', { duration: 1, x: 100, y: 50, opacity: 0, ease: 'quart.out' }, '-=1')
        .from('.gallery_maintitle_dec_2', { duration: 1, x: -200, opacity: 0, ease: 'quart.out' }, '-=1')
        .from('.gallery_txt', { duration: 1, x: -200, opacity: 0, ease: 'quart.out' }, '-=.85')
        .from('.gallery_sec .more_row', { duration: 1, x: -200, opacity: 0, ease: 'quart.out' }, '-=1')
        .from('.gallery_sec .dec_txt', { duration: 1, x: 200, opacity: 0, ease: 'quart.out' }, '-=1')




    tween = gsap.timeline({
            scrollTrigger: {
                trigger: '#trigger_research_sec',
                start: 'top 50%',

            }
        })
        .from('.dec_graph_3', { duration: 1, y: 100, opacity: 0, ease: 'circ.out' })
        .from('.research_maintitle_dec_1', { duration: 1, x: 100, opacity: 0, ease: 'circ.out' }, '-=.8')
        .from('.research_maintitle', { duration: .8, x: 200, opacity: 0, ease: 'quart.out' }, '-=.3')
        .from('.research_txt', { duration: .8, x: 200, opacity: 0, ease: 'quart.out' }, '-=.6')
        .from('.research_sec .more_row', { duration: .8, x: 200, opacity: 0, ease: 'quart.out' }, '-=.8')
        .from('.research_sec .dec_txt', { duration: .8, x: -300, opacity: 0, ease: 'quart.out' }, '-=.6')
        .from('.research_maintitle_dec_2', { duration: .8, x: 300, opacity: 0, ease: 'quart.out' }, '-=.8')




    tween = gsap.timeline({
            scrollTrigger: {
                trigger: '#trigger_contact_sec',
                start: 'top 90%',

            }
        })
        .from('.contact_dec_2', { duration: 2, y: 20, opacity: 0, ease: 'power0.out' })
        .from('.contact_dec_1', { duration: 2, y: 100, opacity: 0, ease: 'power1.out' }, '-=.6')
        .from('.contact_maintitle', { duration: .8, x: -200, opacity: 0, ease: 'quart.out' }, '-=1.4')
        .from('.contact_sec .social_li', { duration: .8, stagger: .15, x: 200, opacity: 0, ease: 'quart.out' }, '-=1.2')
        .from('.contact_right_txt', { duration: .8, x: 200, opacity: 0, ease: 'quart.out' }, '-=.8')
        .from('.contact_copyright', { duration: .8, x: 200, opacity: 0, ease: 'quart.out' }, '-=.7')
        .from('.contact_middle', { duration: .6, y: 30, opacity: 0, ease: 'circ.out' }, '-=.6')
        .from('.contact_dec_3', { duration: 1, rotate: -180, opacity: 0, ease: 'quart.out' }, '-=.4')

});